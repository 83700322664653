import React       from 'react';
import { graphql } from 'gatsby';
import Img         from 'gatsby-image';

import Wrapper       from '@interness/web-core/src/components/structure/Wrapper/Wrapper';
import HeaderMedia   from '@interness/theme-default/src/components/HeaderMedia';
import Heading       from '@interness/web-core/src/components/text/Heading/Heading';
import Spacer        from '@interness/web-core/src/components/structure/Spacer/Spacer';
import Separator     from '@interness/web-core/src/components/structure/Separator/Separator';
import { findMedia } from '@interness/web-core/src/components/_helpers';

import { H3 } from '../components/styles';

function PolyesterbaenderPage(props) {
  const pdf = findMedia(props.data.pdf.media, '5-polyesterband').publicURL;
  return (
    <>
      <HeaderMedia id={'polyesterbaender'}/>
      <Wrapper>
        <Spacer/>
        <Heading>Polyesterbänder</Heading>
        <Spacer height={20}/>
        <p>
          Unsere Polyesterbänder haben viele Einsatzgebiete und bringen wesentliche Vorteile mit.
          Sie werden eingesetzt zur Umrandung von Fahnen, Matratzen und Bewickeln von Kupferdrähten und Schienen.
          Da ihre Reißfestigkeit sehr hoch ist, finden sie ebenfalls Verwendung als Bandage und auch Umreifung von
          Paletten.
          An hohe UV Beständigkeit der Polyesterfaser kommen nur ganz wenig andere Fasern heran.
        </p>
        <Separator/>
        <H3>Spezifikation</H3>
        <table>
          <tbody>
          <tr>
            <td><b>Bezeichnung</b></td>
            <td>Polyesterband</td>
          </tr>
          <tr>
            <td><b>Material</b></td>
            <td>Polyester</td>
          </tr>
          <tr>
            <td><b>Gewebebindung</b></td>
            <td>LW - Bindung</td>
          </tr>
          <tr>
            <td><b>Gewebestellung</b></td>
            <td>Kette : 27 Fd Tex 17/1 per cm,
              Schuß : 12 Fd x2 Tex 17/1 per cm
            </td>
          </tr>
          <tr>
            <td><b>Gewicht</b></td>
            <td>75 g / 100m / per cm</td>
          </tr>
          <tr>
            <td><b>Reißfestigkeit</b></td>
            <td>160 N per cm</td>
          </tr>
          <tr>
            <td><b>Reißdehnung</b></td>
            <td>ca. 18 – 30 %</td>
          </tr>
          <tr>
            <td><b>Erweichungstemperatur</b></td>
            <td>235°</td>
          </tr>
          <tr>
            <td><b>Heisschrumpf bei 130°</b></td>
            <td>12 %</td>
          </tr>
          <tr>
            <td><b>Epoxyd Harzaufnahme</b></td>
            <td>100 %</td>
          </tr>
          </tbody>
        </table>
        <a href={pdf} target='_blank'>Download Datenblatt (PDF)</a>
        <Spacer/>
      </Wrapper>
    </>
  )
}

export default PolyesterbaenderPage;

export const query = graphql`
    query {
        pdf: directusMediaCollection(name: {eq: "pdf"}) {
            media {
                file {
                    localFile {
                        name
                        publicURL
                    }
                }
            }
        }
    }
`;